
.duvidas-frequentes-text {
    text-align: left;
    font-size: 1rem;
 
}
.duvidas-frequentes-text > a {
    @extend .duvidas-frequentes-text;
    text-decoration: underline;
 }

.duvida-frequente-accordion-container {
    margin-bottom: 1rem;
}