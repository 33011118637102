@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';

// .title-aderir {
// 	margin-left: auto;
// 	margin-right: auto;
// 	max-width: 90%;
// }

// .texto-aderir {
// 	margin-left: auto;
// 	margin-right: auto;
// 	max-width: 90%;
// }

// @include media-breakpoint-up(lg) {
// 	.title-aderir {
// 		max-width: 60%;
// 	}
// 	.texto-aderir {
// 		max-width: 60%;
// 	}
// }

.fale-conosco-container {
	display: flex;
	flex: 1;
	flex-direction: column;
}

.fale-conosco-termos-de-uso-container {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: flex-end;
}

.horario-atendimento {
	font-size: 0.8rem;
}

@include media-breakpoint-up(sm) {
	.horario-atendimento {
		font-size: 1rem;
	}
}
