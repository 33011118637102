// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

p.card-text,
p.card-text p,
p.card-text a {
	font-size: 0.8rem;

	@include media-breakpoint-up(sm) {
		font-size: 0.9rem;
	}
	@include media-breakpoint-up(lg) {
		font-size: 1rem;
	}
}

p.card-text p {
	margin-bottom: 1rem;
	text-align: justify;
	&.has-text-align-left {
		text-align: left;
	}
	&.has-text-align-center {
		text-align: center;
	}
	&.has-text-align-right {
		text-align: right;
	}
}
