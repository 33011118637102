@import '../../custom.scss';

.accordion-header {
    padding: 0
}

.accordion-card-header {
    display: flex;
    align-items: center;
    color: $light;
    font-weight: bold;
    font-size: 1.1rem;
    background-color: $primary !important;
    cursor: pointer;
    border-radius: 1rem !important;
    @media(max-width:576px){
        font-size: 0.8rem;
    }
    @media(max-width:1447px){
        min-height: 63px;

    }
}

.accordion-card-header-icon {
    display: flex;
    flex-direction: row-reverse;
    flex: 1
}

.accordion-card-body {
    text-align: left;
   
}

.accordion-card-container {
    border-radius: 1rem !important;
    @media(max-width:767px){
        margin-left: 10%;
        margin-right: 10%;
    }
}