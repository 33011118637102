@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.title-aderir {
	margin-left: auto;
	margin-right: auto;
	max-width: 90%;
}

.texto-aderir {
	margin-left: auto;
	margin-right: auto;
	max-width: 90%;
}

@include media-breakpoint-up(lg) {
	.title-aderir {
		max-width: 60%;
	}
	.texto-aderir {
		max-width: 60%;
	}
}

.small-card-circle {
	height: 30px;
	width: 30px;
	min-width: 30px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.small-card-icon {
	height: 15px;
}

@include media-breakpoint-up(sm) {
	.small-card-circle {
		height: 40px;
		width: 40px;
		min-width: 40px;
	}
	.small-card-icon {
		height: 20px;
	}
}

@include media-breakpoint-up(lg) {
	.small-card-circle {
		height: 50px;
		width: 50px;
		min-width: 50px;
	}
	.small-card-icon {
		height: 25px;
	}
}
