@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.card-image {
	padding-left: 1rem;
	padding-right: 1rem;
}

.card-row {
	margin-top: 1rem;
	margin-bottom: 1rem;
}

@include media-breakpoint-up(md) {
	.card-image {
		padding-left: 2rem;
		padding-right: 2rem;
	}
	.card-row {
		margin-top: 1rem;
		margin-bottom: 1rem;
	}
}

@include media-breakpoint-up(lg) {
	.card-image {
		padding-left: 4rem;
		padding-right: 4rem;
	}
	.card-row {
		margin-top: 2rem;
		margin-bottom: 2rem;
	}
}

@include media-breakpoint-up(xl) {
	.card-image {
		padding-left: 5rem;
		padding-right: 5rem;
	}
	.card-row {
		margin-top: 0;
		margin-bottom: 0;
	}
}
