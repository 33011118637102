@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.download-button {
	width: 150px;
}

.download-app-button-title-text {
	font-size: 1rem;
}

@include media-breakpoint-up(lg) {
	.download-button {
		margin-top: 30px;
		width: 200px;
		//box-shadow: 3px 3px 3px black;
		//-webkit-filter: drop-shadow(12px 12px 25px rgba(0, 0, 0, 0.5));
		//-ms-filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=12, OffY=12, Color='#444')";
		filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5));
	}
}
@include media-breakpoint-up(md) {
	.download-app-button-title-text {
		font-size: 2rem;
	}
}
