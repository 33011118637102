@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.mockup-phone {
	position: absolute;
	background-repeat: no-repeat;
	background-position: center;
	background-size: auto 350px;
	height: 350px;
	width: 100%;
	margin-top: 20px;

	-webkit-transition: opacity 0.9s ease;
	-moz-transition: opacity 0.9s ease;
	transition: opacity 0.9s ease;
}

@include media-breakpoint-up(lg) {
	.mockup-phone {
		background-position: center;
		background-size: auto 700px;
		height: 700px;
		margin-top: 0;

		-webkit-transition: opacity 0.9s ease;
		-moz-transition: opacity 0.9s ease;
		transition: opacity 0.9s ease;
	}
}
