@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.card-circle {
	height: 60px;
	width: 60px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.card-icon {
	height: 30px;
}

@include media-breakpoint-up(md) {
	.card-circle {
		height: 80px;
		width: 80px;
	}
	.card-icon {
		height: 40px;
	}
}

@include media-breakpoint-up(lg) {
	.card-circle {
		height: 100px;
		width: 100px;
	}
	.card-icon {
		height: 50px;
	}
}
