@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.dark .parceiro-block {
	background-color: #f5f5f5;
}
.parceiro-block {
	border-width: 1px;
	border-style: solid;
	border-radius: 20px;
	align-content: center;
	justify-content: center;
	text-align: center;
}
.parceiro-item {
	height: 40px;
}

@include media-breakpoint-up(md) {
	.parceiro-item {
		height: 50px;
	}
}
@include media-breakpoint-up(lg) {
	.parceiro-item {
		height: 60px;
	}
}
