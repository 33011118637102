@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.curve-container {
	min-height: 320px;
}

.link:hover {
	opacity: 0.6;
	text-decoration: none;
}

.link-text {
	font-size: 1rem;
}

.logoAlpha {
	background-image: url('../assets/logo_marca_dagua.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}

@include media-breakpoint-up(md) {
	.logoContainer {
		img {
			width: 30 !important;
			height: auto;
		}
	}
}

@include media-breakpoint-up(lg) {
	.curve-container {
		min-height: 200px;
	}
	.logoContainer {
		display: inline;
		padding-left: 0;

		img {
			width: 120px !important;
			height: 72px !important;
		}
	}
}
