@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.footer-whatsapp-img {
	margin-top: -35px;
	height: 50px;
}

/* .footer-whatsapp-container {
	display: flex;
	justify-content: flex-end;
	z-index: 2;
	padding-right: 10vw;
}
 */

.footer-whatsapp-container {
	position: fixed;
	right: 0;
	top: 90%;
	right: 3%;
	z-index: 9999;
}

@include media-breakpoint-up(md) {
	.footer-whatsapp-img {
		margin-top: -70px;
		height: 70px;
	}
}

@include media-breakpoint-up(lg) {
	.footer-whatsapp-img {
		margin-top: -70px;
		height: 70px;
	}
}
