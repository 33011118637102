
.duvidas-frequentes-button {
    color: white !important;
    font-weight: bold !important;
    font-size: 1.1rem !important;
    line-height: 2 !important;
    border-radius: 10px !important;
    min-height: 55px;
    min-width: 220px;
    box-shadow: black;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}